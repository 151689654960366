const getNameByType = (type) => {
    const nameTypeMap = {
        1: 'Condominio',
        2: 'Hogar',
        3: 'Entrada',
        4: 'Miembro de hogar',
        5: 'Usuario',
    };

    return nameTypeMap[parseInt(type)] || 'Tipo desconocido';
}

export { getNameByType };
