import React, { useEffect, useState } from 'react';
import { useAuth } from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { getNameByType } from '../../utils/typeNameTables';

import config from '../../config/config';

export const HomePageIntern = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const [condominios, setCondominios] = useState([]);
  const [entradas, setEntradas] = useState([]);
  const [hogares, setHogares] = useState([]);
  const [miembros, setMiembros] = useState([]);
  const [users, setUsers] = useState([]);

  const [allUsers, setAllUsers] = useState([]);

  const [elementoSeleccionado, setElementoSeleccionado] = useState();

  const handleLogout = () => {
    logout();
  };

  const handleCondominioClickQuery = async (condominioId) => {
    setMiembros([]);
    setUsers([]);
    try {
      const response = await fetch(`${config.REACT_APP_API}/intern/entradas/${condominioId}`);
      const response2 = await fetch(`${config.REACT_APP_API}/intern/hogares/${condominioId}`);

      if (!response.ok || !response2.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      const data2 = await response2.json();
      setEntradas(data);
      setHogares(data2);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const handleHogatClickQuery = async (hogarId) => {
    try {
      const response = await fetch(`${config.REACT_APP_API}/intern/miembros/${hogarId}`);
      const response2 = await fetch(`${config.REACT_APP_API}/intern/users`)
      if (!response.ok || !response2.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      const data2 = await response2.json();
      setMiembros(data);
      setUsers(data2);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const handleElementoSeleccionado = (id, type, name, condominioId) => {

    setElementoSeleccionado([id, type, name, condominioId]);
  }

  const handleEditarClick = () => {
    if (elementoSeleccionado) {
      navigate(`/edit/${elementoSeleccionado[1]}/${elementoSeleccionado[0]}`);
    }
  }

  const handleEliminarClick = async () => {
    try {
      const confirmacion = window.confirm('¿Estás seguro de que quieres eliminar esta entrada?');

      if (confirmacion) {
        const requestOptions = {
          method: 'DELETE',
        };

        const response = await fetch(`${config.REACT_APP_API}/intern/delete/${elementoSeleccionado[1]}/${elementoSeleccionado[0]}`, requestOptions);

        if (!response.ok) {
          throw new Error('No se pudo completar la solicitud de eliminación.');
        }
        window.location.reload(true)
        const data = await response.json();
        console.log('Entrada eliminada exitosamente:', data);
      }
    } catch (error) {
      console.error('Error al eliminar la entrada:', error.message);
    }
  };

  const handleAñadirClick = (type, idPadre) => {
    navigate(`/add/${type}/${idPadre}`);
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${config.REACT_APP_API}/intern/condominios`);
        const response2 = await fetch(`${config.REACT_APP_API}/intern/users`)
        if (!response.ok || !response2.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        const data2 = await response2.json();
        setCondominios(data);
        setAllUsers(data2);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  return (
    <div style={{ position: 'relative' }}>
      <button
        onClick={handleLogout}
        className="absolute top-0 right-0 bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
        style={{ margin: '10px' }}
      >
        Cerrar sesión
      </button>
      <br />

      <ul>
        {condominios.map(condominio => (
          <li key={condominio.id}>
            <div>
              <button
                onClick={() => {
                  handleCondominioClickQuery(condominio.id)
                  handleElementoSeleccionado(condominio.id, 1, condominio.nombre, condominio.id)
                }}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2 mb-2"
              >
                {condominio.nombre}
              </button>

              <div>
                {entradas
                  .filter(entrada => entrada.condominio_id === condominio.id)
                  .map(entrada => (
                    <button
                      onClick={() => {
                        handleElementoSeleccionado(entrada.id, 3, entrada.identificacion_entrada, condominio.id)
                      }}
                      key={entrada.id}
                      className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mr-2 mb-2"
                    >
                      {entrada.identificacion_entrada}
                    </button>
                  ))}
                {elementoSeleccionado && elementoSeleccionado[3] === condominio.id && (
                  <button
                    onClick={() => {
                      handleAñadirClick(3, condominio.id)
                    }}
                    className="bg-green-900 hover:bg-green-800 text-white font-bold py-2 px-4 rounded mr-2 mb-2"
                  >
                    Añadir Entrada
                  </button>
                )}
              </div>

              <div>
                {hogares
                  .filter(hogar => hogar.condominio_id === condominio.id)
                  .map((hogar, index, array) => (
                    <div key={hogar.id} style={{ display: 'inline-block' }}>
                      <button
                        onClick={() => {
                          handleHogatClickQuery(hogar.id)
                          handleElementoSeleccionado(hogar.id, 2, hogar.direccion, condominio.id)
                        }}
                        className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded mr-2 mb-2"
                      >
                        {hogar.direccion}
                      </button>
                      {miembros
                        .filter(miembro => miembro.hogar_id === hogar.id)
                        .map(miembro => {
                          const usuario = users.find(user => user.id === miembro.user_id);
                          if (usuario) {
                            return (
                              <div key={miembro.id} style={{ display: 'inline-block' }}>
                                <button
                                  onClick={() => {
                                    handleElementoSeleccionado(miembro.id, 4, usuario.name, condominio.id)
                                  }}
                                  className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded mr-2 mb-2"
                                >
                                  {usuario.name}
                                </button>
                              </div>
                            );
                          } else {
                            return null;
                          }
                        })}
                      {elementoSeleccionado && elementoSeleccionado[0] === hogar.id && elementoSeleccionado[3] === condominio.id && elementoSeleccionado[1] === 2 && (
                        <button
                          onClick={() => handleAñadirClick(4, hogar.id)}
                          className="bg-yellow-900 hover:bg-yellow-800 text-white font-bold py-2 px-4 rounded mr-2 mb-2"
                        >
                          Añadir Miembro
                        </button>
                      )}
                    </div>
                  ))
                }
                {elementoSeleccionado && elementoSeleccionado[3] === condominio.id && (
                  <button
                    onClick={() => {
                      handleAñadirClick(2, condominio.id)
                    }}
                    className="bg-purple-900 hover:bg-purple-800 text-white font-bold py-2 px-4 rounded mr-2 mb-2"
                  >
                    Añadir Hogar
                  </button>
                )}
              </div>
            </div>
          </li>
        ))}
      </ul>

      <div style={{ position: 'fixed', top: '50%', right: '10px', transform: 'translateY(-50%)' }}>
        <div>
          <button onClick={() => handleAñadirClick(1, 0)} className="bg-blue-900 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded mr-2 mb-2">Añadir condominio</button>
        </div>
        <h2>{elementoSeleccionado ? `${getNameByType(elementoSeleccionado[1])} ${elementoSeleccionado[2]} ` : 'Ningún elemento seleccionado'}</h2>
        <button onClick={() => handleEditarClick()} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2 mb-2">Editar</button>
        <button onClick={() => handleEliminarClick()} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mr-2 mb-8">Eliminar</button>
        <div>
          <h2>Lista de Usuarios</h2>
          <button onClick={() => {
            handleAñadirClick(5, 0)
          }}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2 mb-2">Añadir usuario</button>
          <ul>
            {allUsers.map(user => (
              <li key={user.id} style={{ borderBottom: '1px solid #ccc', padding: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span>{user.name}</span>
                <div>
                  <button onClick={() => {
                    handleElementoSeleccionado(user.id, 5, user.name, 0)
                  }}
                    className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded mr-2 mb-2">Seleccionar</button>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
