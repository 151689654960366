import iphoneBg from "../../assets/home/img/BG + iPhone/BG + iPhone.png";
import background from "../../assets/home/img/BG.svg";
import users from "../../assets/home/img/Usuarios 2/Usuarios.png";
import Header from '../../components/Header';
import iphoneHome from "../../assets/home/img/iPhone-home.png";

const scrollVerprecios = () => {
    const pricesSection = document.getElementById('precios');
    pricesSection.scrollIntoView({ behavior: 'smooth' });
};

const Home = () => {
    return (
        <div className="App relative">
            <div className="w-full  flex items-center justify-center">
                <img src={background} alt="RingM background" className="absolute inset-0 w-full h-full object-cover" />
                <div className="flex flex-col md:flex-row items-center justify-center z-10 mx-4 md:ml-10 w-full">
                    <div className="flex-initial md:w-5/12 mb-20 px-4">
                        <Header />
                        <h1 className="text-custom text-4xl mt-20 md:text-5xl lg:text-6xl leading-tight maincontainertitle">
                            Dile adiós a los<br />
                            timbres tradicionales,<br />
                            Bienvenido al futuro
                        </h1>
                        <p className="mt-8 md:mt-20 simple_white_text maincontainersubtitle">
                            Con RingM podrás gestionar el ingreso a tu hogar o condominio de forma segura y conveniente. Regístrate ahora mismo y obtén acceso de inmediato a todas las funciones de nuestra aplicación.
                        </p>

                        <button 
                            onClick={scrollVerprecios} 
                            type="submit"
                            className="bg-custom-blue text-white font-bold py-4 px-8 rounded-lg w-60 mt-12 button-home" >
                            Ver precios
                        </button>

                        <div className="flex items-center mt-8 md:mt-20 hidden-mobile">
                            <div className="flex-initial md:w-6/12 max-w-full md:max-w-min mx-auto md:mx-0 text-center md:text-left">
                                <h1 className="text-custom-orange text-5xl font-bold leading-90">
                                    +100
                                </h1>
                                <p className="max-w-full break-words text-xs leading-26 custom-gray">
                                    Hogares se han unido a la transformación
                                </p>
                            </div>

                            <div className="flex-initial md:w-6/12 relative ml-0 md:ml-8 mt-8 md:mt-0">
                                <img src={users} alt="RingM users" className="w-full" />
                            </div>
                        </div>
                    </div>

                    <div className="hidden md:flex md:flex-none md:w-4/12 ml-0 md:ml-20 mt-12 md:mt-20">
                        <img src={iphoneBg} alt="RingM iphone" className="w-full" />
                    </div>
                    <div className="block md:hidden iphoneimagecontainer">
                        <img src={iphoneHome} alt="RingM iphone" className="w-full" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;
