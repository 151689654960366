import React, { useState } from 'react';
import CryptoJS from 'crypto-js';

const AESDecryptionComponent = () => {
  const [encryptedText, setEncryptedText] = useState('');
  const [decryptedText, setDecryptedText] = useState('');
  const [key, setKey] = useState('');
  const [iv, setIV] = useState('');

  
  const handleDecrypt = () => {
    try {
      const decryptedBytes = CryptoJS.AES.decrypt(decodeURIComponent(encryptedText), key, {
        iv: iv
      });

      const decryptedText = decryptedBytes.toString(CryptoJS.enc.Utf8);
      setDecryptedText(decryptedText);
    } catch (error) {
      console.error('Error al descifrar el texto:', error.message);
    }
  };

  return (
    <div>
      <div>
        <label>Texto de URL cifrado:</label>
        <input
          type="text"
          value={encryptedText}
          onChange={(e) => setEncryptedText(e.target.value)}
        />
      </div>
      <div>
        <label>Clave de cifrado:</label>
        <input
          type="text"
          value={key}
          onChange={(e) => setKey(e.target.value)}
        />
      </div>
      <div>
        <label>Vector de inicialización (IV):</label>
        <input type="text" value={iv} onChange={(e) => setIV(e.target.value)} />
      </div>
      <button onClick={handleDecrypt}>Descifrar</button>
      <div>
        <label>Texto descifrado:</label>
        <div>{decryptedText}</div>
      </div>
    </div>
  );
};

export default AESDecryptionComponent;
