import React, { useState, useEffect, useRef } from 'react';
import Mas from '../../assets/home/SVGfiles/+.svg';
import Menos from '../../assets/home/SVGfiles/menos.svg';
import Comillas from '../../assets/home/img/pricing/comillas.png';
import Atras from '../../assets/home/img/pricing/Arrow-1.svg';
import Adelante from '../../assets/home/img/pricing/Arrow-2.svg';
import emailjs from 'emailjs-com'; 
import testimoniosData from '../../assets/Testimonios/testimonios.json';
import Email from "../../assets/home/SVGfiles/Email.svg";
import FormImage from '../../assets/home/img/formImage.png';
import RingM from "../../assets/Isotipo.png";

const Pricing = () => {
    
    const [stock, setStock] = useState(1);
    const [testimonios, setTestimonios] = useState([]);
    const [testimonioActual, setTestimonioActual] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false); 
    const [formData, setFormData] = useState({
        nombre: '',
        correo: '',
        telefono: '',
        cantidadCasas: stock,
    });
    const [isSubmitting, setIsSubmitting] = useState(false);

    const formRef = useRef(); 

    useEffect(() => {
        if (Array.isArray(testimoniosData)) {
            setTestimonios(testimoniosData);
        }
    }, []);

    useEffect(() => {
        if (isModalOpen) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }
    }, [isModalOpen]);

    useEffect(() => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            cantidadCasas: stock,
        }));
    }, [stock]);

    const incrementStock = () => {
        setStock(stock + 1);
    };

    const decrementStock = () => {
        if (stock > 1) {
            setStock(stock - 1);
        }
    };

    const handleStockChange = (e) => {
        const value = e.target.value;
        if (value === '') {
            setStock(value);
        } else {
            const number = parseInt(value, 10);
            if (!isNaN(number) && number > 0) {
                setStock(number);
            }
        }
    };

    const handleStockBlur = () => {
        if (stock === '') {
            setStock(1);
        }
    };

    const mostrarSiguienteTestimonio = () => {
        setTestimonioActual((testimonioActual + 1) % testimonios.length);
    };

    const mostrarTestimonioAnterior = () => {
        const anterior = (testimonioActual - 1 + testimonios.length) % testimonios.length;
        setTestimonioActual(anterior);
    };

    const calcularPrecio = (stock) => {
        const IVA = 0.19;
        let uf = 1.9;
        if (stock > 30) {
            uf += (Math.ceil((stock - 30) / 10)) * 0.1;
            if (uf > 2.9) {
                uf = 2.9;
            }
        }
        const precioConIVA = uf * (1 + IVA);
        const precioSinIVA = uf;
        return precioSinIVA.toFixed(2);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsSubmitting(true); 

        const templateParams = {
            nombre: formData.nombre,
            correo: formData.correo,
            telefono: formData.telefono,
            cantidadCasas: stock,
        };

        emailjs.send('service_bh235po', 'template_tssvo1q', templateParams, 'eiYNDxW4fyXp2qpw8')
            .then((result) => {
                console.log(result.text);
                setFormSubmitted(true); 
                setIsSubmitting(false); 
            }, (error) => {
                console.error(error.text);
                setIsSubmitting(false); 
            });

        setFormData({
            nombre: '',
            correo: '',
            telefono: '',
            cantidadCasas: 1,
        });
        setStock(1);
    };

    const scrollToModule = () => {
        const moduleElement = document.getElementById('modal');
        if (moduleElement) {
            moduleElement.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className='bg-white all-pricing' id='precios'>
            <div className='grid place-items-center'>
                <h5 className='text-custom-blue font-bold text-custom-blue2'>Precios</h5>
                <p className="title-custom3 mx-0">
                    Nuestro precio es simple sin
                </p>
                <p className="title-custom3 mx-0">
                    tarifas ocultas
                </p>
            </div>
            <div className='flex justify-center mt-20' id='modal'> 
                <div className='bg-custom-light-orange pricing-container particular'>
                    <div className='pricing-type absolute justify-center'>
                        <p className='pricing-style'>
                            CASAS PARTICULARES
                        </p>
                    </div>
                    <h1 className="title-custom-xl45">
                        Proximamente
                        <p className='text-custom-medium-blue3'></p>
                    </h1>
                    <p className='absolute text-custom-small-blue2'>Pago por 1 hogar</p>
                    <button className='absolute pricing-button-disabled'>PROXIMAMENTE</button>
                </div>
                <div className='bg-custom-light-blue pricing-container ml-20 condominio'>
                    <div className='pricing-type absolute justify-center'>
                        <p className='pricing-style'>
                            CONDOMINIOS O COMUNIDADES
                        </p>
                    </div>
                    <h1 className="title-custom-xl2 absolute">
                        UF {calcularPrecio(stock)}
                        <p className='text-custom-medium-blue3'> + IVA / mes</p>
                    </h1>
                    <div>
                        <button className='button-container2' onClick={decrementStock}>
                            <img src={Menos} alt="Menos"/>
                        </button>
                        <input
                            className='text-custom-medium-blue2 mx-6 mr-8 bg-custom-light-blue stockdisplay'
                            value={stock}
                            onChange={handleStockChange}
                            onBlur={handleStockBlur}
                            min="1"
                        />
                        <button className='button-container2' onClick={incrementStock}>
                            <img src={Mas} alt="Mas"/>
                        </button>
                    </div>
                    <button className='absolute pricing-button' onClick={() => { setIsModalOpen(true); scrollToModule();}}>Empezar a usar RingM</button>
                </div>
            </div>

            <div className='flex mt-72 justify testimonios-container-fix'>
                <div> 
                    <h5 className='text-custom-blue font-bold'>
                        TESTIMONIOS
                    </h5>
                    <p className="title-custom4 mx-0">
                        Lo que opinan
                    </p>
                    <p className="title-custom5 mx-0">
                        nuestros usuarios
                    </p>
                    <div className="flex mt-4">
                        <button className='button-container' onClick={mostrarTestimonioAnterior}>
                            <img src={Atras} alt="Atrás" className='testimoniosbutons'/>
                        </button>
                        <button className='button-container' onClick={mostrarSiguienteTestimonio}>
                            <img src={Adelante} alt="Adelante" className='testimoniosbutons'/>
                        </button>
                    </div>
                </div>   
                <div>
                    <img src={Comillas} alt="Comillas" className='abrecomillas ml-6 mb-10'/>
                    <div className="testimonios-container">
                        <p className="testimonios-text">{testimonios[testimonioActual]?.descripcion}</p>
                    </div>
                    <img src={Comillas} alt="Comillas" className='cierracomillas mt-8'/>
                    <div className='ml-20'>
                        <p className="testimonios-nombre-text">{testimonios[testimonioActual]?.nombre}</p>
                        <p className="testimonios-usuario-text">{testimonios[testimonioActual]?.usuario}</p>
                    </div>
                </div>            
            </div>

            {isModalOpen && (
                <div className='modal'>
                    {/*<p className='simple-form-font' >Ingresa tus datos y responderemos a tu solicitud en breve!</p>*/}
                    <div className='modal-content'>
                        <img src={RingM} alt='RingMlogo' className='modal-logoRm'/> 
                        <img src={FormImage} alt="Formulario Imagen" className='fotoformulario' />
                        <span className='close' onClick={() => setIsModalOpen(false)}>&times;</span>
                        {formSubmitted ? (
                            <div className="simple-black-font2 modal-summary2">
                                <p>Listo! te contactaremos en breve.</p>
                            </div>
                        ) : (
                            <>
                                <h2 className='title-form'>Solicita Ahora!</h2>
                                <div className="flex">
                                    <form ref={formRef} onSubmit={handleSubmit} className="flex flex-col gap-4 ml-4 content-form-text">
                                        <label className='text-custom-small-blue6 flex flex-col'>
                                            Nombre:
                                            <input type="text" name="nombre" value={formData.nombre} onChange={handleInputChange} required className="justmobilefix border border-black rounded px-4 py-2 mt-2" />
                                        </label>
                                        <label className='text-custom-small-blue6 flex flex-col'>
                                            Correo:
                                            <input type="email" name="correo" value={formData.correo} onChange={handleInputChange} required className="justmobilefix border border-black rounded px-4 py-2 mt-2" />
                                        </label>
                                        <label className='text-custom-small-blue6 flex flex-col'>
                                            Teléfono:
                                            <input type="tel" name="telefono" value={formData.telefono} onChange={handleInputChange} required className="justmobilefix border border-black rounded px-4 py-2 mt-2" />
                                        </label>
                                        <button className='button-form mt-4 self-center' type="submit">
                                            {isSubmitting ? 'Cargando...' : 'Solicitar!'}
                                        </button>
                                    </form>
                                </div>
                            </>
                        )}
                    </div>

                    {!formSubmitted && (
                        <div className="simple-black-font modal-summary">
                            <p>ESTÁS SOLICITANDO:</p>
                            <p>CONDOMINIOS O COMUNIDADES</p>
                            <p>CANTIDAD: x{stock}</p>
                            <p>TOTAL: UF {calcularPrecio(stock)} + IVA</p>
                            <br />
                        </div>
                    )}
                </div>
            )}

        </div>
    );
}

export default Pricing;
