import React, { useState } from 'react';
import CryptoJS from 'crypto-js';

const AESEncryptionComponent = () => {
  const [plainText, setPlainText] = useState('');
  const [key, setKey] = useState('');
  const [iv, setIV] = useState('');
  const [encryptedText, setEncryptedText] = useState('');
  const [urlSafeEncryptedText, setUrlSafeEncryptedText] = useState('');

  const handleEncrypt = () => {
    
    try {
      const key_ = CryptoJS.enc.Hex.parse(key);
      const iv_ = CryptoJS.enc.Hex.parse(iv);

      const encryptedBytes = CryptoJS.AES.encrypt(plainText, key_, {
        iv: iv_
      });

      const encryptedText = encryptedBytes.toString();
      setEncryptedText(encryptedText);

      
      const urlSafeEncryptedText = encodeURIComponent(encryptedText);
      setUrlSafeEncryptedText(urlSafeEncryptedText);
    } catch (error) {
      console.error('Error al cifrar el texto:', error.message);
    }
  };

  return (
    <div>
      <div>
        <label>Texto plano:</label>
        <input
          type="text"
          value={plainText}
          onChange={(e) => setPlainText(e.target.value)}
        />
      </div>
      <div>
        <label>Clave de cifrado:</label>
        <input
          type="text"
          value={key}
          onChange={(e) => setKey(e.target.value)}
        />
      </div>
      <div>
        <label>Vector de inicialización (IV):</label>
        <input type="text" value={iv} onChange={(e) => setIV(e.target.value)} />
      </div>
      <button onClick={handleEncrypt}>Cifrar</button>
      <div>
        <label>Texto cifrado:</label>
        <div>{encryptedText}</div>
        <label>Texto cifrado seguro para URL:</label>
        <div>{urlSafeEncryptedText}</div>
      </div>
    </div>
  );
};

export default AESEncryptionComponent;
