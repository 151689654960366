import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";
import axios from 'axios';
import "./Invitacion.css";
import config from './../../config/config';
import RingM from "../../assets/home/img/RingM/RingM.png";
import Loadingimage from '../../assets/home/img/loadingbutton/Animation - 1726521832162.gif';

const Invitacion = () => {
  const { jwtShortToken } = useParams();
  const [entradas, setEntradas] = useState([]);
  const [hogarId_, setHogarId] = useState(0);
  const [condominioId_, setCondominioId] = useState(0);
  const [userId_, setUserId] = useState(0);
  const [name_, setName] = useState('');
  const [patente_, setPatente] = useState('');
  const [fechaInicio_, setFechaInicio] = useState('');
  const [fechaFin_, setFechaFin] = useState('');

  const [expirado, setExpirado] = useState(false);
  const [abriendoBarrera, setAbriendoBarrera] = useState(false); 
  const [entradaActual, setEntradaActual] = useState(''); 

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const response = await axios.get(`${config.REACT_APP_API}/tokenVisit/${jwtShortToken}`);
        const { token: fullJwtToken } = response.data;

        const decodedToken = jwtDecode(fullJwtToken);
        setCondominioId(decodedToken.condominioId);
        setHogarId(decodedToken.hogarId);
        setUserId(decodedToken.userId);
        setName(decodedToken.name);
        setPatente(decodedToken.patente);
        setFechaInicio(formatDate(decodedToken.fechaInicio));
        setFechaFin(formatDate(decodedToken.fechaFin));

        const fechaFin = new Date(decodedToken.fechaFin);
        const fechaActual = new Date();

        if (fechaActual > fechaFin) {
          setExpirado(true);
        }

        const entradasResponse = await axios.get(`${config.REACT_APP_API}/entradas/${decodedToken.condominioId}`);
        setEntradas(entradasResponse.data);
        console.log('Entradas:', entradasResponse.data);
      } catch (error) {
        console.error('Error al obtener y decodificar el token:', error);
      }
    };

    fetchToken();
  }, [jwtShortToken]);

  const handlePortonClick = (condominioId, entrada, entradaName) => {
    setAbriendoBarrera(true);
    setEntradaActual(entradaName); 

    setTimeout(() => {
      setAbriendoBarrera(false);
    }, 3000);

    axios.post(`${config.REACT_APP_API}/integrations/porton/${condominioId}/${entrada}`)
      .then(response => {
        console.log('Respuesta de la API del portón:', response.data);
      })
      .catch(error => {
        console.error('Error al llamar a la API del portón:', error);
      });
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1; 
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();

    const formattedDay = String(day).padStart(2, '0');
    const formattedMonth = String(month).padStart(2, '0');
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');

    return `${formattedDay}/${formattedMonth}/${year} ${formattedHours}:${formattedMinutes}`;
  }

  return (
    <div className="invitation-container">
      <div>
      <img src={RingM} alt="RingM logo" className="mx-auto absolute inset-x-0 top-5" />
      {expirado ? (
        <div className='flex flex-col justify-center items-center'>
          <h1>Invitación expirada</h1>
          <p>{fechaFin_}</p>
          <div className='absolute inset-x-0 bottom-0 h-20'>
            <p className='flex justify-center items-center'>¿Problemas con tu invitacion?</p>
            <a className='flex justify-center items-center underline' href='#'> Haz click aquí </a>
          </div>
        </div>
      ) : (
        <div className="description-container mx-auto">
          <div>
            {name_ && <div>Nombre: {name_}</div>}
            {patente_ && <div>Patente: {patente_}</div>}
            {fechaInicio_ && <div>Fecha de inicio: {fechaInicio_}</div>}
            {fechaFin_ && <div>Fecha de fin: {fechaFin_}</div>}
            <div className="buttons-container">
              {entradas.map(entrada => (
                <button
                  key={entrada.id}
                  className="button"
                  onClick={() => {
                    handlePortonClick(condominioId_, entrada.index, entrada.identificacion_entrada)
                  }}
                >
                  {entrada.identificacion_entrada} - Abrir barrera
                </button>
              ))}
            </div>
            {abriendoBarrera && (
              <div className="modal">
                  <img src={Loadingimage} alt='loadingasset' className='loading-asset' />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
    </div>
  );
};

export default Invitacion;
