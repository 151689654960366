const axios = require('axios');
const config = require('../config/config');

export const generatePassword = async (plainTextPassword) => {
  try {
    const response = await axios.post(`${config.REACT_APP_API}/intern/generatePassword`, {
      plainTextPassword
    });
    return response.data;
  } catch (error) {
    console.error('Error al generar la contraseña:', error.response);
  }
};
