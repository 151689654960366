import RingM from "../../assets/home/img/RingM 2/RingM.png";
import SecurityIcon from "../../assets/home/icon/Seguridad.svg";
import GestionaIcon from "../../assets/home/icon/Gestiona.svg";
import GestionaIcon1 from "../../assets/home/icon/Gestiona (1).svg";
import Residentes from "../../assets/home/img/Residentes/Residentes.png";
import Arrow from "../../assets/home/SVGfiles/Arrow-3.svg";
import Iphonebackground from '../../assets/home/img/Iphonefondogris/BG - Mobile@2x.png';
import Check from '../../assets/home/SVGfiles/Check-1.svg';
import CasaPlaya from '../../assets/home/img/CasasRingMe/Casa de la playa.png';
import CasaManuel from '../../assets/home/img/CasasRingMe/Casa de Manuel.png';
import CasaPrincipal from '../../assets/home/img/CasasRingMe/Casa Principal.png';
import Notificaciones from '../../assets/home/img/notificaciones/Notificaciones.png';
import Invitaciones from '../../assets/home/img/notificaciones/Invitaciones.png';

const Product = () => {
    return (
        <div className='all-product' id="acerca-de">
            <div className='mt-40'>
                <div className="grid grid-cols-3 gap-3 ">
                    <div></div>
                    <div className='grid place-items-center'>
                        <h5 className='text-custom-blue mb-2 text-xl text-custom-blue24'>Acerca de</h5>
                        <h1 className="text-custom-deep-blue text-xl md:text-6xl lg:text-5xl xl:text-4xl font-bold flex items-center mb-2 mobile-product-subtitle">
                            Simplifica el acceso a tu
                        </h1>
                        <h1 className="text-custom-deep-blue text-xl md:text-6xl lg:text-5xl xl:text-4xl font-bold flex items-center mobile-product-subtitle2">
                            hogar con <img src={RingM} alt="RingM logo" className="flex h-12 ml-4 mt-2 ringM-logo" />
                        </h1>
                    </div>
                    <div></div>
                </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mx-6 md:mx-16 lg:mx-24 my-24">
                <div className="p-6 md:p-8 lg:p-10 relative mx-auto margin-correction-product" style={{ width: "100%", maxWidth: "350px", height: "299px" }}>
                    <div className="absolute top-0 left-0">
                        <img src={GestionaIcon} alt="Security Icon" width="80" height="80" className="image-blur" />
                    </div>
                    <p className='text-custom-body mt-20'>Gestiona de manera sencilla los usuarios</p>
                    <p className='text-custom-body'>que pueden ingresar. Invita a tus</p>
                    <p className='text-custom-body'>familiares y amigos como residentes para</p>
                    <p className='text-custom-body'>que puedan acceder de forma</p>
                    <p className='text-custom-body'>permanente.</p>
                </div>
                <div className="p-6 md:p-8 lg:p-10 relative mx-auto margin-correction-product" style={{ width: "100%", maxWidth: "350px", height: "299px" }}>
                    <div className="absolute top-0 left-0">
                        <img src={GestionaIcon1} alt="Security Icon" width="80" height="80" className="image-blur" />
                    </div>
                    <p className='text-custom-body mt-20'>Simplifica tu vida, olvídate de los timbres</p>
                    <p className='text-custom-body'>tradicionales y disfruta de un acceso</p>
                    <p className='text-custom-body'>rápido y conveniente con nuestra</p>
                    <p className='text-custom-body'>aplicación automatizando el ingreso a tu</p>
                    <p className='text-custom-body'>hogar.</p>
                </div>
                <div className="p-6 md:p-8 lg:p-10 relative mx-auto margin-correction-product" style={{ width: "100%", maxWidth: "350px", height: "299px" }}>
                    <div className="absolute top-0 left=0">
                        <img src={SecurityIcon} alt="Security Icon" width="80" height="80" className="image-blur image-blur2" />
                    </div>
                    <p className='text-custom-body mt-20'>RingM utiliza tecnología de seguridad</p>
                    <p className='text-custom-body'>avanzada para proteger el acceso a tu</p>
                    <p className='text-custom-body'>hogar o condominio, garantizamos que</p>
                    <p className='text-custom-body'>solo las personas autorizadas puedan</p>
                    <p className='text-custom-body'>ingresar.</p>
                </div>
                <div className="mt-40"></div>
            </div>

            <div className="relative iphone-container">
                <img src={Iphonebackground} className="iphone-image w-9/12 mx-auto my-auto" />
                <div className="absolute top-0 left-0 w-full text-center">
                    <div className="container mx-auto rounded-2xl p-6 mt-16 image-text-margin-product-fix">
                        <p className="title-custom">
                            La forma más inteligente de
                        </p>
                        <p className='title-custom title-custom2'>
                            recibir visitas
                        </p>
                        <p className="text-pressable hidden-mobile">
                            QUIERO SABER MAS
                            <img src={Arrow} className="ml-1 inline" />
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Product;
