import React, { useState } from 'react';
import RingMLogo from "../assets/home/img/RingM/RingM.png";
import MenuIcon from '../assets/home/img/Menu.png';

const HeaderMobile = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const scrollToAcercade = () => {
        const Acercadesection = document.getElementById('acerca-de');
        Acercadesection.scrollIntoView({ behavior: 'smooth' });
        setIsOpen(false); 
    };

    const scrollToDescubrelo = () => {
        const Descubrelosection = document.getElementById('descubrelo');
        Descubrelosection.scrollIntoView({ behavior: 'smooth' });
        setIsOpen(false); 
    };

    const scrollToPrecios = () => {
        const Preciossection = document.getElementById('precios');
        Preciossection.scrollIntoView({ behavior: 'smooth' });
        setIsOpen(false); 
    };

    return (
        <div className="block md:hidden bg-custom-deeper-blue">
            <div className="flex justify-between items-center p-4">
                <img src={RingMLogo} alt="RingM Logo" className="logo-svg" />
                <button onClick={toggleMenu}>
                    <svg className="w-6 h-6 menu-image" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        {isOpen ? (
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                        ) : (
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
                        )}
                    </svg>
                </button>
            </div>
            {isOpen && (
                <div className="absolute right-4 w-40 bg-white shadow-md menu-list">
                    <ul className="space-y-2 p-2">
                        <li>
                            <a href="#" className="links-styles text-custom-small-blue hover:text-gray-300 text-sm block" onClick={() => setIsOpen(false)}>Inicio</a>
                        </li>
                        <li>
                            <a href="#" className="links-styles text-custom-small-blue hover:text-gray-300 text-sm block" onClick={scrollToAcercade}>Acerca de</a>
                        </li>
                        <li>
                            <a href="#" className="links-styles text-custom-small-blue hover:text-gray-300 text-sm block" onClick={scrollToDescubrelo}>Descúbrelo</a>
                        </li>
                        <li>
                            <a href="#" className="links-styles text-custom-small-blue hover:text-gray-300 text-sm block" onClick={scrollToPrecios}>Precios</a>
                        </li>
                    </ul>
                </div>
            )}
        </div>
    );
};

export default HeaderMobile;
