import React from 'react';
import RingMLogo from "../assets/home/img/RingM/RingM.png";
import HeaderMobile from './HeaderMobile'; // Importar el componente HeaderMobile

const Header = () => {

    const scrollToAcercade = () => {
        const Acercadesection = document.getElementById('acerca-de');
        Acercadesection.scrollIntoView({ behavior: 'smooth' });
    };

    const scrollToDescubrelo = () => {
        const Descubrelosection = document.getElementById('descubrelo');
        Descubrelosection.scrollIntoView({ behavior: 'smooth' })
    }

    const scrollToPrecios = () => {
        const Preciossection = document.getElementById('precios');
        Preciossection.scrollIntoView({ behavior: 'smooth' })
    }

    return (
        <div>
            {/* Header para la versión de escritorio */}
            <div className='App relative hidden md:block'>
                <div className="flex items-center space-x-16">
                    <img src={RingMLogo} alt="RingM Logo" className="logo-svg" />
                    <div className="flex space-x-16 mt-10 header-class">
                        <a href="#" className="links-styles text-white hover:text-gray-300 text-sm">Inicio</a>
                        <a href="#" className="links-styles text-white hover:text-gray-300 text-sm" onClick={scrollToAcercade}>Acerca de</a>
                        <a href="#" className="links-styles text-white hover:text-gray-300 text-sm" onClick={scrollToDescubrelo}>Descúbrelo</a>
                        <a href="#" className="links-styles text-white hover:text-gray-300 text-sm" onClick={scrollToPrecios}>Precios</a>
                    </div>
                </div>
            </div>

            {/* Header para la versión móvil */}
            <HeaderMobile />
        </div>
    );
};

export default Header;
