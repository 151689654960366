import React, { useEffect, useState, useRef } from "react";
import AgoraRTC from "agora-rtc-sdk-ng";
import { useParams } from 'react-router-dom';
import axios from 'axios';
import "./Visita.css";
import RingM from "../../assets/home/img/RingM/RingM.png";
import config from './../../config/config';

const APP_ID = "21bdfb3a904745abacd81c20d28d9324";

const Visita = () => {
  const { token } = useParams();
  const [hogares, setHogares] = useState([]);

  const [inCall, setInCall] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isCameraEnabled, setIsCameraEnabled] = useState(true);
  const [isMicrophoneMuted, setIsMicrophoneMuted] = useState(false);

  const [nameHogar, setNameHogar] = useState(null);
  const [callerNameInput, setCallerNameInput] = useState("");
  const [nameCondominio, setNameCondominio] = useState(null);

  const client = useRef(null);
  const localTracks = useRef([]);
  const remoteUsers = useRef({});
  const resolution = '180p';


  useEffect(() => {
    try {
      axios.get(`${config.REACT_APP_API}/UnannouncedVisit/${encodeURIComponent(token)}`)
        .then(response => {
          setHogares(response.data);
          console.log('Hogares:', response);
        })
        .catch(error => {
          console.error('Error al obtener los hogares:', error);
        });
    } catch (error) {
      console.error('Error al decodificar el token:', error);
    }
  }, [token]);

  const generateToken = async ( channelName, uid) => {
  
    const url = `${config.REACT_APP_API}/integrations/access_token?uid=${uid}&channel=${channelName}`;
    console.log(url);
    try {
      const response = await axios.get(url);
      return response.data.token;
    } catch (error) {
      console.error('Error generating token:', error);
      throw error;
    }
  };

  useEffect(() => {
    if (client.current) {
      client.current.on('user-published', handleUserJoined);
      client.current.on('user-left', handleUserLeft);

      return () => {
        client.current.off('user-published', handleUserJoined);
        client.current.off('user-left', handleUserLeft);
      };
    }
  }, []);

  const handleUserJoined = async (user, mediaType) => {
    remoteUsers.current[user.uid] = user;
    await client.current.subscribe(user, mediaType);
  
    if (mediaType === 'video') {
      let player = `<div class="video-container" id="user-container-${user.uid}">
                      <div class="remote-video-player" id="user-${user.uid}"></div> 
                    </div>`;
      document.getElementById('video-streams').insertAdjacentHTML('beforeend', player);
  
      user.videoTrack.play(`user-${user.uid}`);
    }
  
    if (mediaType === 'audio') {
      user.audioTrack.play();
    }
  };

  const handleUserLeft = (user) => {
    const userContainer = document.getElementById(`user-container-${user.uid}`);
    if (userContainer) {
      userContainer.remove();
    }
    delete remoteUsers.current[user.uid];
  };

  const joinStream = async (channelName) => {
    setIsLoading(true);

    if (client.current === null) {
      client.current = AgoraRTC.createClient({
        mode: 'rtc',
        codec: 'vp8'
      });
    }

    client.current.on('user-published', handleUserJoined);
    client.current.on('user-left', handleUserLeft);

    try {
      const token = await generateToken(channelName, 0);

      await client.current.join(APP_ID, channelName, token, null);

      localTracks.current = await AgoraRTC.createMicrophoneAndCameraTracks();
      
      localTracks.current[1].setEncoderConfiguration({
        resolution: resolution,
        frameRate: { max: 30 }, 
        orientationMode: 'adaptative'
      });

      let player = `<div class="video-container local-video-container" id="user-container-0">
                    <div class="local-video-player" id="user-0"></div>
                </div>`;
      document.getElementById('video-streams').innerHTML = player;

      localTracks.current[1].play('user-0');

      await client.current.publish([localTracks.current[0], localTracks.current[1]]);

      setInCall(true);
      return token;
    } catch (error) {
      if (error.response && error.response.status === 403) {
        alert(error.response.data.error);
      } else {
        alert(error.response.data.error);
        console.error('Error al unirse a la transmisión:', error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const videoStreamsContainer = document.getElementById('video-streams');

  const leaveAndRemoveLocalStream = async () => {
    for (let i = 0; i < localTracks.current.length; i++) {
      localTracks.current[i].stop();
      localTracks.current[i].close();
    }

    await client.current.leave();
    setInCall(false);

    while (videoStreamsContainer.firstChild) {
      videoStreamsContainer.removeChild(videoStreamsContainer.firstChild);
    }
  };

  const toggleCamera = () => {
    const videoTrack = localTracks.current[1];
    if (videoTrack) {
      if (isCameraEnabled) {
        videoTrack.setEnabled(false);
      } else {
        videoTrack.setEnabled(true);
      }
      setIsCameraEnabled(!isCameraEnabled);
    }
  };

  const toggleMicrophone = () => {
    const audioTrack = localTracks.current[0];
    if (audioTrack) {
      if (isMicrophoneMuted) {
        audioTrack.setEnabled(true);
      } else {
        audioTrack.setEnabled(false);
      }
      setIsMicrophoneMuted(!isMicrophoneMuted);
    }
  };

  const sendNotification = async (tokenFCM, nameCall, channel, tokenAgora, condominio_id) => {

    const response = await fetch(`${config.REACT_APP_API}/integrations/notificacion`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ tokenFCM: tokenFCM, name: nameCall, channel: channel, tokenagora: tokenAgora, condominio_id: condominio_id }),
    });

    if (!response.ok) {
      throw new Error('Error al intentar enviar la notificacion');
    }
  };
  
  return (
<>
  <br></br>
  {!inCall && (
  <img src={RingM} alt="RingM logo" className="mx-auto" />
  )}
  <br></br>
  <div className="custom-blue flex justify-center items-center">
    <div id="stream-wrapper" className="bg-white rounded-lg shadow-lg p-6" style={{ maxWidth: '100%', padding: '2rem' }}>
      {!inCall && (
        <input
          type="text"
          placeholder="¿Quien está en la puerta?"
          value={callerNameInput}
          onChange={(e) => setCallerNameInput(e.target.value)}
          className="input_custom"
        />
      )}
      {callerNameInput === "" && !inCall && (
        <p className="text-custom-medium">Ingrese su nombre</p>
      )}
      {inCall && (
        <h2>{nameCondominio}</h2>
      )}
      {inCall && (
        <h3>{nameHogar}</h3>
      )}
      <div id="video-streams">
    <div className="local-video-container"></div>
  </div>
      {isLoading && <div className="loading-screen">Cargando...</div>}
      {!inCall && (
        <div className="bg-white rounded-lg shadow-lg p-4">
          {hogares.map((hogar, index) => (
            <div key={index}>
              <button
                type="button" 
                className="custom-button my-2"
                onClick={async () => {
                  
                  if (!callerNameInput.trim()) {
                    alert('Ingrese su nombre para continuar');
                  }
                  if (callerNameInput !== "") {
                    const channel = hogar.direccion.toLowerCase().replace(/\s+/g, '_');
                    const tokenAgora = await joinStream(channel);
                    try {
                      await sendNotification(hogar.jefe_token_fcm, callerNameInput, channel, tokenAgora, hogar.condominio_id);
                      console.log('Notificación enviada correctamente');
                    } catch (error) {
                      console.error('Error al enviar la notificación:', error);
                    }
                    
                    setNameCondominio(hogar.nombre_condominio);
                    setNameHogar(hogar.direccion);
                  }
                }}
              >
                {hogar.direccion}
              </button>
            </div>
          ))}
        </div>
      )}
      <div>
      {inCall && (
        <img src={RingM} alt="RingM logo" className="floating-logo" />
      )}
      </div>
      {inCall && (
        <div id="controls-wrapper">
  <div id="controls-container">
    <button className={`button ${isCameraEnabled ? 'button' : 'red-button'} mx-2`} onClick={toggleCamera}>
      {isCameraEnabled ? <i className="material-icons">videocam</i> : <i className="material-icons">videocam_off</i>}
    </button>
    <button className="button exit mx-2" id="leave-btn" onClick={leaveAndRemoveLocalStream}>
      Colgar
    </button>
    <button className={`button ${!isMicrophoneMuted ? 'button' : 'red-button'} mx-2`} onClick={toggleMicrophone}>
      {isMicrophoneMuted ? (
        <i className="material-icons">mic_off</i>
      ) : (
        <i className="material-icons">mic</i>
      )}
    </button>
  </div>
</div>     
      )}
    </div>
  </div>
</>

  );
};

export default Visita;

