import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div>
      <h1>Política de Privacidad</h1>
      <p><strong>Fecha de entrada en vigor: 1 de julio de 2024</strong></p>

      <h2>1. Introducción</h2>
      <p>
        En RingM valoramos la privacidad de nuestros usuarios. Esta política de privacidad describe cómo recopilamos,
        utilizamos y protegemos la información personal que nos proporcionas al utilizar nuestra aplicación.
      </p>

      <h2>2. Información que Recopilamos</h2>
      <p>Recopilamos los siguientes tipos de información:</p>
      <ul>
        <li>
          <strong>Información Personal:</strong> Incluye, pero no se limita a, tu nombre, dirección de correo electrónico,
          número de teléfono y cualquier otra información que proporciones voluntariamente.
        </li>
        <li>
          <strong>Información de Uso:</strong> Datos sobre cómo utilizas la aplicación, incluyendo tu dirección IP, tipo de
          dispositivo, sistema operativo y comportamiento de navegación.
        </li>
      </ul>

      <h2>3. Uso de la Información</h2>
      <p>Utilizamos la información recopilada para:</p>
      <ul>
        <li>Proporcionar y mejorar nuestros servicios.</li>
        <li>Enviarte notificaciones importantes.</li>
        <li>Responder a tus comentarios y preguntas.</li>
      </ul>

      <h2>4. Compartición de la Información</h2>
      <p>No compartimos tu información personal con terceros, excepto en las siguientes circunstancias:</p>
      <ul>
        <li>Con tu consentimiento explícito.</li>
        <li>Para cumplir con la ley o responder a procesos legales válidos.</li>
        <li>Para proteger los derechos, propiedad o seguridad de RingM y nuestros usuarios.</li>
      </ul>

      <h2>5. Seguridad de la Información</h2>
      <p>
        Implementamos medidas de seguridad para proteger tu información personal contra acceso no autorizado, alteración,
        divulgación o destrucción. Sin embargo, ninguna transmisión de datos por Internet es completamente segura y no
        podemos garantizar la seguridad absoluta de tu información.
      </p>

      <h2>6. Retención de la Información</h2>
      <p>
        Retenemos tu información personal durante el tiempo que sea necesario para cumplir con los propósitos descritos en
        esta política, a menos que la ley requiera o permita un período de retención más largo.
      </p>

      <h2>7. Tus Derechos</h2>
      <p>
        Tienes el derecho de acceder, corregir o eliminar tu información personal que poseemos. Puedes ejercer estos derechos
        contactándonos en test@ringm.cl.
      </p>

      <h2>8. Cambios a esta Política de Privacidad</h2>
      <p>
        Nos reservamos el derecho de actualizar esta política de privacidad en cualquier momento. Te notificaremos sobre
        cualquier cambio publicando la nueva política en nuestro sitio web y/o aplicación. Te recomendamos revisar esta
        política periódicamente para estar informado sobre cómo protegemos tu información.
      </p>

      <h2>9. Contacto</h2>
      <p>
        Si tienes preguntas o preocupaciones sobre esta política de privacidad, puedes contactarnos en:
      </p>
      <p>
       
        RingM<br />
        test@ring.cl
      </p>
    </div>
  );
};

export default PrivacyPolicy;