import React, { useState } from 'react';
import Home from './pages/Homepage/Home';
import Product from './pages/Homepage/product';
import Product2 from './pages/Homepage/Product2';
import Pricing from './pages/Homepage/pricing';
import Footer from './pages/Homepage/Footer';

const Homepage = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <>
            <div className={``} style={{ backgroundColor: isModalOpen ? 'rgba(0, 0, 0, 0.7)' : '#FFFFFF' }}>
                <Home/>
                <Product/>
                <Product2/>
                <Pricing setIsModalOpen={setIsModalOpen} />
                <Footer/>
            </div>
        </>
    );
}

export default Homepage;
