import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getNameByType } from '../../utils/typeNameTables';

import config from '../../config/config';

export const EditPageIntern = () => {
    const navigate = useNavigate();
    const { type, id } = useParams();

    const [definitions, setDefinitions] = useState([]);
    const [formData, setFormData] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchDefinitions = async () => {
            try {
                const response = await fetch(`${config.REACT_APP_API}/intern/definitions/${type}`);
                const response2 = await fetch(`${config.REACT_APP_API}/intern/get/${type}/${id}`);
                
                if (!response.ok || !response2.ok) {
                    throw new Error('No se pudo obtener las definiciones de los campos.');
                }
        
                const data = await response.json();
                const data2 = await response2.json();
        
                const formattedData = data.filter(definition => {
                    return ['options', 'int', 'text'].includes(definition.inputType);
                }).map(definition => {
                    return {
                        fieldName: definition.fieldName,
                        inputType: definition.inputType,
                        options: definition.options || null
                    };
                });
        
                const filteredData2 = {};
                Object.keys(data2).forEach(key => {
                    if (formattedData.some(definition => definition.fieldName === key)) {
                        filteredData2[key] = data2[key];
                    }
                });
        
                setDefinitions(formattedData);
                setFormData(filteredData2);
                setLoading(false);
            } catch (error) {
                console.error('Error al obtener las definiciones de los campos:', error);
            }
        };
        
        fetchDefinitions();
    }, [type, id]);

    const handleInputChange = (fieldName, value) => {
        setFormData({
            ...formData,
            [fieldName]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const url = `${config.REACT_APP_API}/intern/save/${type}/${id}`;
            
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await response.json();
            console.log('Save successful:', result);
            navigate('/secret');
        } catch (error) {
            console.error('Error saving data:', error);
        }

    };

    if (loading) {
        return <div>Cargando...</div>;
    }

    return (
        <div className="p-4">
            <h1 className="text-2xl font-bold mb-4">Edit Page</h1>
            <h1 className="text-lg font-bold mb-4">{getNameByType(type)}</h1>

            <form onSubmit={handleSubmit}>
                {definitions.map((definition, index) => (
                    <div key={index} className="mb-4">
                        {(() => {
                            switch (definition.inputType) {
                                case 'options':
                                    return (
                                        <>
                                            {definition.fieldName !== undefined && definition.fieldName !== '' && (
                                                <label htmlFor={definition.fieldName} className="block text-sm font-bold text-white-700">
                                                    {definition.fieldName}
                                                </label>
                                            )}
                                            <select
                                                id={definition.fieldName}
                                                name={definition.fieldName}
                                                value={formData[definition.fieldName] || ''}
                                                onChange={(e) => handleInputChange(definition.fieldName, e.target.value)}
                                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                style={{ color: '#000', backgroundColor: '#fff' }}
                                            >
                                                <option value="">Seleccionar opción</option>
                                                {definition.options &&
                                                    Object.entries(definition.options).map(([value, label]) => (
                                                        <option key={value} value={value}>
                                                            {label}
                                                        </option>
                                                    ))}
                                            </select>
                                        </>
                                    );
                                case 'int':

                                    return (
                                        <>
                                            {definition.fieldName !== undefined && definition.fieldName !== '' && (
                                                <label htmlFor={definition.fieldName} className="block text-sm font-bold text-white-700">
                                                    {definition.fieldName}
                                                </label>
                                            )}
                                            <input
                                                type="number"
                                                id={definition.fieldName}
                                                name={definition.fieldName}
                                                value={formData[definition.fieldName] || ''}
                                                onChange={(e) => handleInputChange(definition.fieldName, parseInt(e.target.value))}
                                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                            />

                                        </>
                                    );
                                case 'text':
                                    return (
                                        <>
                                            {definition.fieldName !== undefined && definition.fieldName !== '' && (
                                                <label htmlFor={definition.fieldName} className="block text-sm font-bold text-white-700">
                                                    {definition.fieldName}
                                                </label>
                                            )}
                                            <input
                                                type="text"
                                                id={definition.fieldName}
                                                name={definition.fieldName}
                                                value={formData[definition.fieldName] || ''}
                                                onChange={(e) => handleInputChange(definition.fieldName, e.target.value)}
                                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                            />
                                        </>
                                    );
                                default:
                                    return null;
                            }
                        })()}
                    </div>
                ))}

                <button
                    type="submit"
                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    Save
                </button>
            </form>
        </div>
    );
};

