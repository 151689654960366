import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { getNameByType } from '../../utils/typeNameTables';
import { generatePassword } from '../../utils/generatePassword';
import config from './../../config/config';

export const AddPageIntern = () => {
    const navigate = useNavigate();
    const { type, idPadre } = useParams();

    const [definitions, setDefinitions] = useState([]);
    const [formData, setFormData] = useState({});

    const handleInputChange = (fieldName, value) => {
        setFormData({
            ...formData,
            [fieldName]: value,
        });
    };

    const [userOptions, setUserOptions] = useState([]);

    useEffect(() => {
        const fetchUserOptions = async () => {
            try {
                const response = await fetch(`${config.REACT_APP_API}/intern/users`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                const options = data.map(user => ({ value: user.id, label: user.name }));
                setUserOptions(options);
            } catch (error) {
                console.error('Error fetching user options:', error);
            }
        };
        fetchUserOptions();
    }, []);

    useEffect(() => {
        const fetchDefinitions = async () => {
            try {
                const response = await fetch(`${config.REACT_APP_API}/intern/definitions/${type}`);
                if (!response.ok) {
                    throw new Error('No se pudo obtener las definiciones de los campos.');
                }
                const data = await response.json();
                const formattedData = data.map(definition => {
                    return {
                        fieldName: definition.fieldName,
                        inputType: definition.inputType,
                        options: definition.options || null
                    };
                });
                setDefinitions(formattedData);
            } catch (error) {
                console.error('Error al obtener las definiciones de los campos:', error);
            }
        };
        fetchDefinitions();
    }, [type]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const parentDefinition = definitions.find(definition => definition.inputType === 'parent');
            const parentField = parentDefinition ? parentDefinition.fieldName : null;

            let updatedFormData = { ...formData };

            if ('password' in updatedFormData) {
                updatedFormData = {
                    ...updatedFormData,
                    password: generatePassword(updatedFormData.password)
                };
            }
            
            if (parentField !== null) {
                updatedFormData = {
                    ...updatedFormData,
                    [parentField]: idPadre
                };
            }

            const url = `${config.REACT_APP_API}/intern/add/${type}`;

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedFormData),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await response.json();
            console.log('Save successful:', result);
            navigate('/secret');
        } catch (error) {
            console.error('Error saving data:', error);
        }
    };

    return (
        <div className="p-4">
            <h1 className="text-2xl font-bold mb-4">Añadir {getNameByType(type)}</h1>

            <form onSubmit={handleSubmit}>
                {definitions.map((definition, index) => (
                    <div key={index} className="mb-4">
                        {(() => {
                            switch (definition.inputType) {
                                case 'options':
                                    return (
                                        <>
                                            {definition.fieldName !== undefined && definition.fieldName !== '' && (
                                                <label htmlFor={definition.fieldName} className="block text-sm font-bold text-white-700">
                                                    {definition.fieldName}
                                                </label>
                                            )}
                                            <select
                                                id={definition.fieldName}
                                                name={definition.fieldName}
                                                value={formData[definition.fieldName] || ''}
                                                onChange={(e) => handleInputChange(definition.fieldName, e.target.value)}
                                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                style={{ color: '#000', backgroundColor: '#fff' }}
                                            >
                                                <option value="">Seleccionar opción</option>
                                                {definition.options &&
                                                    Object.entries(definition.options).map(([value, label]) => (
                                                        <option key={value} value={value}>
                                                            {label}
                                                        </option>
                                                    ))}
                                            </select>
                                        </>
                                    );
                                case 'int':
                                    return (
                                        <>
                                            {definition.fieldName !== undefined && definition.fieldName !== '' && (
                                                <label htmlFor={definition.fieldName} className="block text-sm font-bold text-white-700">
                                                    {definition.fieldName}
                                                </label>
                                            )}
                                            <input
                                                type="number"
                                                id={definition.fieldName}
                                                name={definition.fieldName}
                                                value={formData[definition.fieldName] || ''}
                                                onChange={(e) => handleInputChange(definition.fieldName, parseInt(e.target.value))}
                                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                            />

                                        </>
                                    );
                                case 'text':
                                    return (
                                        <>
                                            {definition.fieldName !== undefined && definition.fieldName !== '' && (
                                                <label htmlFor={definition.fieldName} className="block text-sm font-bold text-white-700">
                                                    {definition.fieldName}
                                                </label>
                                            )}
                                            <input
                                                type="text"
                                                id={definition.fieldName}
                                                name={definition.fieldName}
                                                value={formData[definition.fieldName] || ''}
                                                onChange={(e) => handleInputChange(definition.fieldName, e.target.value)}
                                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                            />
                                        </>
                                    );
                                case 'password':
                                    return (
                                        <>
                                            {definition.fieldName !== undefined && definition.fieldName !== '' && (
                                                <label htmlFor={definition.fieldName} className="block text-sm font-bold text-white-700">
                                                    {definition.fieldName}
                                                </label>
                                            )}
                                            <input
                                                type="password"
                                                id={definition.fieldName}
                                                name={definition.fieldName}
                                                value={formData[definition.fieldName] || ''}
                                                onChange={(e) => handleInputChange(definition.fieldName, e.target.value)}
                                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                            />
                                        </>
                                    );
                                case 'parent':
                                    return (
                                        <input
                                            type="hidden"
                                            name={definition.fieldName}
                                            value={idPadre}
                                        />
                                    );
                                case 'user':
                                    return (
                                        <>
                                            {definition.fieldName !== undefined && definition.fieldName !== '' && (
                                                <label htmlFor={definition.fieldName} className="block text-sm font-bold text-white-700">
                                                    {definition.fieldName}
                                                </label>
                                            )}
                                            <select
                                                id={definition.fieldName}
                                                name={definition.fieldName}
                                                value={formData[definition.fieldName] || ''}
                                                onChange={(e) => handleInputChange(definition.fieldName, e.target.value)}
                                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                style={{ color: '#000', backgroundColor: '#fff' }}
                                            >
                                                <option value="">Seleccionar usuario</option>
                                                {userOptions.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </select>
                                        </>
                                    );
                                default:
                                    return null;
                            }
                        })()}
                    </div>
                ))}
                <button
                    type="submit"
                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    Añadir
                </button>
            </form>
        </div>
    );


};
