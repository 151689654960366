import DualPhones from '../../assets/home/img/Iphonedual/iPhone Dual.png';
import Email from "../../assets/home/SVGfiles/Email.svg";
import AppStore from '../../assets/home/img/appygoogle/Apple.svg';
import Googleplay from '../../assets/home/img/appygoogle/Google Play.svg';
import Faqs from "../../assets/home/SVGfiles/Faqs.svg";
import RingM from "../../assets/Isotipo.png";
import Whatsapp from '../../assets/home/img/Redes/Whatsapp.svg';
import Instagram from '../../assets/home/img/Redes/Instagram.svg';

const scrollVerprecios = () => {
    const pricesSection = document.getElementById('precios');
    pricesSection.scrollIntoView({ behavior: 'smooth' });
};

const Footer = () =>
{
    return (
        <div className='justify footer-style-all' >
            <div>
                <img src={DualPhones} alt="Dual Phones" className="mr-6 dualphonessize"/>
                    <h1 className='text-custom controlaelaccesotexto-style'>Controla el acceso </h1>
                    <h1 className='text-custom controlaelaccesotexto-style2'> a tu hogar</h1>
                    <p className='registrateyobtenacceso-style'>
                        Regístrate y obtén acceso inmediato
                    </p> 
                    <div className="flex flex-col md:flex-row items-center mt-8 md:mt-12 button-ver-precios-style">
                    <button 
                            onClick={scrollVerprecios} 
                            type="submit"
                            className="bg-custom-blue text-white font-bold py-4 px-8 rounded-lg w-60 mt-12" >
                            Ver precios
                        </button>
                        </div>
                        <div className="flex ggplayyapplecontainer">
                            <div className="store-container">
                                <img src={Googleplay} alt="Google Play Logo" /> 
                                <p className='ml-4'>
                                    Google Play
                                </p>
                            </div> 
                            <div className="store-container ml-12"> 
                                <img src={AppStore} alt="AppStore Logo" />
                                <p className='ml-4'>
                                    App Store
                                </p>  
                            </div>
                        </div>
                        <div className='preguntasyfaqscontainer'>
                        <img src={Faqs} alt="FAQS" className='Faqsstyle'/>
                            <h1 className='text-custom mt-8'>
                                ¿Tienes preguntas?
                            </h1>
                            <p className='text-custom mt-4 opacity-20'>
                                FAQs
                            </p>
                        </div>
                        <div className='contactoscontainer'>
                        <img src={Email} alt="EMAIL" className='emailstyle'/>
                            <h1 className='text-custom mt-8'>
                                Contáctanos
                            </h1>
                            <p className='text-custom mt-4 opacity-20'>
                                info@ringm.cl
                            </p>
                        </div>

                        <div className='flex footer-container-foot'>
                            <img src={RingM} alt="ringMe Logo" className='footerringMlogo'/>
                            <p className='todoslosderechosreservados opacity-20'>
                                Todos los derechos reservados 2023
                            </p>
                            <p className='terminosycondiciones opacity-20'>
                                Terminos y condiciones
                            </p>
                            <div className='flex rrss'>
                                <div className='flex'>
                                    <img src={Instagram} alt="Instagram" />
                                </div>
                                <div className='flex ml-6'>
                                    <img src={Whatsapp} alt="Whatsapp" />
                                </div>
                            </div>
                        </div>
            </div>
        </div>
    );
}

export default Footer;