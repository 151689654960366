import RingM from "../../assets/home/img/RingM 2/RingM.png";
import SecurityIcon from "../../assets/home/icon/Seguridad.svg";
import GestionaIcon from "../../assets/home/icon/Gestiona.svg";
import GestionaIcon1 from "../../assets/home/icon/Gestiona (1).svg";
import Residentes from "../../assets/home/img/Residentes/Residentes.png";
import Arrow from "../../assets/home/SVGfiles/Arrow-3.svg";
import Iphonebackground from '../../assets/home/img/Iphonefondogris/BG - Mobile@2x.png';
import Check from '../../assets/home/SVGfiles/Check-1.svg';
import CasaPlaya from '../../assets/home/img/CasasRingMe/Casa de la playa.png';
import CasaManuel from '../../assets/home/img/CasasRingMe/Casa de Manuel.png';
import CasaPrincipal from '../../assets/home/img/CasasRingMe/Casa Principal.png';
import Notificaciones from '../../assets/home/img/notificaciones/Notificaciones.png';
import Invitaciones from '../../assets/home/img/notificaciones/Invitaciones.png';

const Product2 = () => {
    return (
        <>
                        <div className="mt-20 descubrelo-container" id="descubrelo">
                <div className='container mx-auto flex items-center'>
                    <div>
                        <p className='text-pressable mt-60 '>
                            DESCÚBRELO
                        </p>
                        <h1 className='title-custom-sm flex items-center'>
                            Mejora tu seguridad y
                        </h1>
                        <h1 className='title-custom-sm flex items-center correct-padding-title-custom-sm'>
                            control con RingM
                        </h1>
                        <div className="residentes-wrapper">
    <div className="residentes-container">
        <img src={Residentes} alt="iphone residentes" className="residentes-img" />
    </div>
    <div className="residentes-content">
        <div className="flex mt-12">
            <img src={Check} alt="Check Icon" className="mr-6 check2"/>
            <p className="text-custom-deep-blue text-lg md:text-xl lg:text-2xl xl:text-3xl font-bold flex items-center residentesyvisitastextfix">
                Residentes y visitas
            </p>
        </div>
        <div className="text-content">
            <p className='text-custom-body textoresidentesyvisitas'>Podrás invitar a tus familiares y amigos a formar parte como </p>
            <p className='text-custom-body textoresidentesyvisitas'>residentes, brindándoles un acceso fácil y conveniente,</p>
            <p className='text-custom-body textoresidentesyvisitas'>Además, controla el acceso temporal para visitas</p>
            <p className='text-custom-body textoresidentesyvisitas'>ocasionales. Gestiona de forma segura quién puede entrar</p>
            <p className='text-custom-body textoresidentesyvisitas'> a tu hogar.</p>
        </div>
    </div>
</div>
                    </div>
                    <div className="contacts-example flex justify-center items-center ce-correction">
                        <div className="flex flex-col justify-center items-center mt-10">
                            <img src={CasaPlaya} alt="Casa playa" className="casa-imagen casa-playa mb-10" />
                            <img src={CasaManuel} alt="Casa manuel" className="casa-imagen casa-manuel mb-10" />
                            <img src={CasaPrincipal} alt="Casa principal" className="casa-imagen casa-principal mb-10" />
                        </div>
                    </div>

                
                    <div className='absolute multipleshogares-container'>
                        <div className="absolute mx-auto flex items-center" style={{ width: "928px", textAlign: "center", color: "blue", transform: 'translateX(920px) translateY(200px)' }}>
                            <img src={Check} alt="Check Icon" className="mr-6 just-multipleshogares-text"/>
                            <p className="text-custom-deep-blue text-lg md:text-xl lg:text-2xl xl:text-3xl font-bold just-multipleshogares-text2">
                                Multiples hogares
                            </p>
                        </div>
                        <div className='relative' style={{ transform: 'translateX(1010px) translateY(270px)' }}>
                            <p className='text-custom-body fix-multipleshogares'>Puedes formar parte de múltiples hogares. Mantén tus</p>
                            <p className='text-custom-body fix-multipleshogares'>conexiones cercanas y seguras, y accede fácilmente a</p>
                            <p className='text-custom-body fix-multipleshogares'>cada uno de ellos desde una sola aplicación.</p>
                        </div>
                    </div>

                    <div className="control-invitaciones">
                        <div className="notification-container">
                            <img src={Notificaciones} alt="Notificaciones" className="image" />
                            <img src={Invitaciones} alt="Invitaciones" className="image" />
                        </div>
                    </div>
                    <div className='absolute'>
                        <div className="absolute mx-auto flex items-center" style={{ width: "928px", textAlign: "center", color: "blue", transform: 'translateX(920px) translateY(1190px)' }}>
                            <img src={Check} alt="Check Icon" className="mr-6 check3"/>
                            <p className="text-custom-deep-blue text-lg md:text-xl lg:text-2xl xl:text-3xl font-bold controldeinvitacionesmaintext">
                                Control de invitaciones
                            </p>
                        </div>
                        <div className='relative' style={{ transform: 'translateX(1010px) translateY(1270px)' }}>
                            <p className='text-custom-body controlinvitacionestext'>Cuando un usuario te añada como visitante o residente,</p>
                            <p className='text-custom-body controlinvitacionestext'>recibirásuna notificación de invitación. Tendrás el poder</p>
                            <p className='text-custom-body controlinvitacionestext'>de aceptar o rechazar.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Product2;
